import React, { useState } from 'react';
import Button from '../Button/Button';
import styled from 'styled-components';
import { Input, Label } from '../Forms';
import LoadingSpinner from '../LoadingSpinner';
import Flexbox from '../Flexbox';
import { navigate } from 'gatsby';
import fetch from '../../utils/fetch';
import { Paragraph } from '../Typography';
import Notification from '../Notification/Notification';

const formInputs = [
    {
        name: 'name',
        type: 'text',
        label: 'Name of Occupier',
        Component: Input,
        required: true,
    },
    {
        name: 'house_number',
        type: 'text',
        label: 'House Name/Numbers',
        Component: Input,
        required: true,
    },
    {
        name: 'postcode',
        type: 'text',
        label: 'Postcode',
        Component: Input,
        required: true,
    },
    {
        name: 'date',
        type: 'date',
        label: 'Date you have occupied property from',
        Component: Input,
        required: true,
        width: '200px',
    },
];

const ProofOfResidencyForm = () => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setError] = useState();

    const [formData, setFormData] = useState({});

    function handleFieldChange(e) {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    function handleFileChange(e) {
        e.persist();

        setFormData((prevState) => ({
            ...prevState,
            attachments: e.target.files,
        }));
    }

    async function handleSubmit(e) {
        e.preventDefault();

        const data = new FormData();

        Object.entries(formData).forEach(([key, value]) => {
            if (key === 'attachments') {
                const fileValues = Object.values(value);
                fileValues.forEach((file) => {
                    data.append('attachments[]', file, file.name);
                });
            } else data.append(key, value);
        });

        try {
            setLoading(true);
            await fetch.get('/api/csrf-cookie');
            const response = await fetch.post('/api/user/submit-proof', data);
            if (response.status !== 200) {
                navigate('/web-services/form-submit-error', {
                    replace: true,
                });
            }
            if (window) {
                navigate('/web-services/form-submitted', {
                    state: {
                        authenticated: true,
                    },
                    replace: true,
                });
            }
        } catch (error) {
            const response = error.response;
            if(!error.response){
                return setError('Unable to connect with server. Please try again, if this continues please contact us.');
            }
            if(error.response.status===500){
                return setError('Server error. Please try again, if this continues please contact us.');
            }
            if(response.status===413){
                return setError('File size exceeded. If you require this document to be uploaded, please get in touch with us directly.');
            }
            if (response.status === 422) {
                const backendErrors = Object.values(response.data.errors || {});

                if(!backendErrors || backendErrors[0] || backendErrors[0][0]){
                    return setError('Invalid data provided, please check the form and try again.');
                }
                return setError(backendErrors[0][0]);
            }
            return setError(
                'Something went wrong, please try again. If this continues please contact us.'
            );
        }
    }

    return (
        <Form id='contact-form' onSubmit={handleSubmit}>
            {formInputs.map((field) => (
                <Input
                    key={field.name}
                    label={field.label}
                    name={field.name}
                    type={field.type}
                    onChange={handleFieldChange}
                    required={field.required}
                    width={field.width}
                />
            ))}
            <Flexbox flexDirection='column' alignItems='start' mt={8} mb={24}>
                <Label htmlFor='attachments'>Upload Attachment</Label>
                <input
                    label='Attach file'
                    name='attachments'
                    id='attachments'
                    type='file'
                    onChange={handleFileChange}
                    style={{ fontSize: '12px', marginTop: '8px' }}
                    multiple
                    required
                />
                <Paragraph fontSize='15px' mt={16}>
                    Accepted file types include pdf, doc, docx, gif, jpeg, jpg, png, rtf, txt, odt
                </Paragraph>
            </Flexbox>
            <Button type='submit' width='100%'>
                {loading && !errorMessage ? <LoadingSpinner /> : 'Submit'}
            </Button>
            {errorMessage && (
                <Notification
                    mt='24px'
                    fontSize='16px'
                    maxWidth='fit-content'
                    padding='8px'
                    justifyContent='center'
                >
                    {errorMessage}
                </Notification>
            )}
        </Form>
    );
};

export default ProofOfResidencyForm;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    max-width: 370px;
`;
