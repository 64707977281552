import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import defaultTheme from '../../utils/themes';
import Flexbox from '../Flexbox';

export const Input = ({
    name,
    type,
    placeholder,
    onChange,
    value,
    label,
    required,
    width,
}) => {
    return (
        <>
            <Label htmlFor={name}>{label}</Label>
            <InputStyle
                id={name}
                name={name}
                type={type}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                required={required ? 'required':undefined}
                width={width}
            />
        </>
    );
};

export const TextArea = ({ name, label, placeholder, onChange, value }) => {
    return (
        <Flexbox flexDirection='column' alignItems='start' width='100%'>
            <Label htmlFor={name}>{label}</Label>
            <TextAreaStyle
                id={name}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
            />
        </Flexbox>
    );
};

const InputStyle = styled.input`
    border: 1px solid ${defaultTheme.colors.border};
    height: 40px;
    background-color: ${defaultTheme.colors.grey};
    margin-bottom: 24px;
    border-radius: 2px;
    padding: 8px;
    width: ${(props) => props.width};
    color: ${defaultTheme.colors['text-grey']};
    font-size: 18px;

    &:focus {
        outline: none;
        box-shadow: 0 0 1px 1px#5d72da;
    }
`;

export const Label = styled.label`
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 6px;
    user-select: none;
    line-height: 10px !important;
`;

const TextAreaStyle = styled.textarea`
    resize: vertical;
    border-radius: 2px;
    border: 1px solid ${defaultTheme.colors.border};
    background-color: ${defaultTheme.colors.grey};
    height: 200px;
    width: 100%;
    padding: 16px;
    color: ${defaultTheme.colors['text-grey']};
    font-size: 18px;

    &:focus {
        outline: none;
        box-shadow: 0 0 1px 1px#5d72da;
    }
`;

InputStyle.defaultProps = {
    width: '100%',
};

Input.defaultProps = {
    type: 'text',
};

Input.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf([
        'text',
        'number',
        'password',
        'file',
        'email',
        'date',
    ]),
    value: PropTypes.any,
    label: PropTypes.any,
    onChange: PropTypes.func,
    required: PropTypes.bool,
};

TextArea.propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.any,
    onChange: PropTypes.func,
};
