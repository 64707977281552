import { Heading1, Paragraph } from '../../components/Typography';
import HeroSection from '../../components/HeroSection';
import ProofOfResidencyForm from '../../components/ProofOfResidencyForm/ProofOfResidencyForm';
import React from 'react';
import SEO from '../../components/seo';
import Section from '../../components/Section';

const SubmitProofPage = () => (
  <>
    <SEO
      title='Submit Proof of Residency'
      description={`
            If you have received a letter addressed to somebody who does not live at your address, we would be grateful if you could supply us with proof of your residency so that we can remove your details from our system and stop any further action being taken by ourselves.
            `}
    />
    <HeroSection paddingTop='8px'>
      <Heading1 marginTop='120px'>Submit Proof of Residency</Heading1>
    </HeroSection>

    <Section backgroundColor='white' paddingTop='100px' paddingBottom='215px'>
      <div
        css={`
          max-width: 800px;
        `}
      >
        <Paragraph>
          If you have received a letter addressed to somebody who does not live
          at your address, we would be grateful if you could supply us with
          proof of your residency so that we can remove your details from our
          system and stop any further action being taken by ourselves. We will
          also advise our clients of your details, so that they may update their
          records.
        </Paragraph>

        <Paragraph>
          The proof required can be a copy of your Tenancy Agreement, or simply
          a utility bill showing your name against the address in question.
        </Paragraph>
        <ProofOfResidencyForm />
      </div>
    </Section>
  </>
);

export default SubmitProofPage;
